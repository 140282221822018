import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

const ErrorPage = (props: { error?: Error }) => {
  const { t } = useTranslation();
  if (!props.error) {
    return null;
  }

  return (
    <Box mb={2} >

      <Typography component="h1" variant="h4" align="center" color="textPrimary">
        Secret <Box component="span" sx={{ fontWeight: '200' }}>Does Not Exist</Box>
      </Typography>
      <Typography color="textPrimary" align="center"  variant="body1" mt={1} mb={2}>{t('error.subtitle')}</Typography>

      <Typography variant="h5" mt={2} align="center">{t('error.titleOpened')}</Typography>
      <Typography variant="body1" color="textPrimary" mt={1}>
        {t('error.subtitleOpenedBefore')}
      </Typography>
      <Typography variant="body1" color="textPrimary" mt={1}>
        {t('error.subtitleOpenedCompromised')}
      </Typography>

      <Typography variant="h5" align="center" mt={2}>{t('error.titleBrokenLink')}</Typography>
      <Typography variant="body1" color="textPrimary" mt={1}>
        {t('error.subtitleBrokenLink')}
      </Typography>

      <Typography variant="h5" align="center" mt={2}>{t('error.titleExpired')}</Typography>
      <Typography variant="body1" color="textPrimary" mt={1}>
        {t('error.subtitleExpired')}
      </Typography>
    </Box>
  );
};
export default ErrorPage;
