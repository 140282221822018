import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography } from '@mui/material';
import { saveAs } from 'file-saver';
import { useEffect } from 'react';
import DeleteSecret from './DeleteSecret';
import CopyBtn from '../shared/CopyBtn';

const RenderSecret = ({
  secret,
  secretUrl
}: { readonly secret: string, readonly secretUrl?: string; readonly oneTime: boolean; }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Typography component="h1" variant="h4" align="center" color="textPrimary">
        Decrypted <Box component="span" sx={{ fontWeight: '200' }}>Message</Box>
      </Typography>
      <Typography color="textPrimary" align="center"  variant="body1" mt={1} mb={2}>{t('secret.subtitleMessage')}</Typography>
      <Typography
        id="pre"
        data-test-id="preformatted-text-secret"
        color="textPrimary"
        sx={{
          backgroundColor: '#f2f2f2',
          padding: '15px',
          border: '1px solid rgba(0, 0, 0, 0.26)',
          display: 'block',
          fontSize: '1rem',
          borderRadius: '4px',
          wordWrap: 'break-word',
          wordBreak: 'break-all',
          whiteSpace: 'pre-line',
          fontFamily: 'Monospace',
          mb: 2
        }}
      >
        {secret}
      </Typography>

      <Box>
        <Grid container justifyContent="center" gap={1}>
          <Grid item xs={7} sx={{ textAlign: 'center'}}>
            <CopyBtn value={secret} fullWidth={true} text={t('secret.buttonCopy')} />
          </Grid>
          <Grid item xs={4} textAlign="center">
              <DeleteSecret url={secretUrl ?? ''} />
          </Grid>
        </Grid>
      </Box>

    </div>
  );
};

const DownloadSecret = ({
  secret,
  fileName,
}: {
  readonly secret: string;
  readonly fileName: string;
}) => {
  // const { t } = useTranslation();

  useEffect(() => {
    saveAs(
      new Blob([secret], {
        type: 'application/octet-stream',
      }),
      fileName,
    );
  }, [fileName, secret]);

  return (
    <div>
      <Typography component="h1" variant="h4" align="center" color="textPrimary">
        File <Box component="span" sx={{ fontWeight: '200' }}>Downloaded</Box>
      </Typography>
    </div>
  );
};

const Secret = ({
  secret,
  fileName,
  secretUrl,
  oneTime,
}: {
  readonly secret: string;
  readonly fileName?: string;
  readonly secretUrl?: string;
  readonly oneTime: boolean;
}) => {
  if (fileName) {
    return <DownloadSecret fileName={fileName} secret={secret} />;
  }

  return <RenderSecret secret={secret} secretUrl={secretUrl} oneTime={oneTime} />;
};

export default Secret;
