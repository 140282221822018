import { Box, Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';

export const Attribution = () => {
  const { t } = useTranslation();

  const translationAttribution = () => {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {t('attribution.translatedBy')}{' '}
        <Link href={t('attribution.translatorLink')}>
          {t('attribution.translatorName')}
        </Link>
      </Typography>
    );
  };

  return (
    <Box flexGrow="1" display="flex" flexDirection="column" justifyContent="flex-end">
      <Typography my={3} variant="body2" color="textSecondary" align="center">
        Maintained with
        <FontAwesomeIcon icon={faHeart} style={{ margin: '0 0.4em' }} />
        by
        <Link href="https://codewell.co">
          <Box
            sx={{
              width: 'auto',
              height: '20px',
              opacity: '0.6',
              verticalAlign: 'middle',
              margin: '0 0 0.4em 0.4em'
            }}
            display="inline-block"
            component="img"
            alt="Codewell"
            src="codewell.png"
          />
        </Link>
      </Typography>
      {t('attribution.translatorName') && translationAttribution()}
    </Box>
  );
};
