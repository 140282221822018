import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import {
  Alert,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  // DialogContentText,
  DialogActions,
  useMediaQuery,
  useTheme, Typography,
  Snackbar,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import Error from '../shared/Error';
import { isErrorWithMessage } from '../utils/utils';

const deleteSecret = async (url: string): Promise<Response> => {
  return await fetch(url, {
    method: 'DELETE',
    referrerPolicy: 'strict-origin'
  });
};

const DeleteSecret = ({ url }: { readonly url: string }) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [open, setOpen] = useState(false);

  const [deleted, setDeleted] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(true);

  const onDelete = async (): Promise<void> => {
    setLoading(true);
    setError('');
    try {
      const response = await deleteSecret(url);

      if (![204,404].includes(response.status)) {
        const data = await response.json();
        setError(data.message);
      } else {
        setDeleted(true);
        setOpen(false);
      }
    } catch (e) {
      if (isErrorWithMessage(e)) {
        setError(e.message);
      }
    }
    setLoading(false);
  };

  const handleConfirmOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (deleted) {
    return (
      <>
        <Snackbar open={snackbarOpen} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                  onClose={() => setSnackbarOpen(false)}>
          <Alert severity="success" sx={{ width: '100%' }}>{t('delete.messageDeleted')}</Alert>
        </Snackbar>
        <Button variant="text" fullWidth color="secondary" sx={{ py:1, px:3 }} startIcon={<FontAwesomeIcon icon={faTrash} />}  disabled>
          {t('delete.buttonDelete')}
        </Button>
      </>
    );
  }

  return (
    <>
      <Snackbar open={deleted && snackbarOpen} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={() => setSnackbarOpen(false)}>
        <Alert severity="success" sx={{ width: '100%' }}>{t('delete.messageDeleted')}</Alert>
      </Snackbar>

      <Button variant="text" fullWidth color="secondary" sx={{ py:1, px:3 }} startIcon={<FontAwesomeIcon icon={faTrash} />} onClick={handleConfirmOpen} disabled={deleted}>
        {t('delete.buttonDelete')}
      </Button>

      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="delete-dialog-title"
      >
        <DialogTitle id="delete-dialog-title">
          <Typography color="textPrimary">{t('delete.dialogTitle')}</Typography>
        </DialogTitle>
        <DialogContent>
            <Error message={error} />
            <Typography color="textSecondary">{t('delete.dialogMessage')}</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleClose}
            color="secondary"
            disabled={loading}
            variant="text"
          >
            {t('delete.dialogCancel')}
          </Button>
          <Button
            onClick={onDelete}
            color="primary"
            autoFocus
            disabled={loading}
            variant="contained"
          >
            {loading ? t('delete.dialogProgress') : t('delete.dialogConfirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteSecret;
