import { Controller, UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup, Typography,
} from '@mui/material';

export const Expiration = (props: { control: UseFormMethods['control'] }) => {
  const { t } = useTranslation();
  return (
    <FormControl component="fieldset" margin="dense">
      <FormLabel component="legend" sx={{ textAlign: 'center'}}><Typography component="span" variant="body2" color="textSecondary">{t('expiration.legend')}</Typography></FormLabel>
      <Controller
        rules={{ required: true }}
        control={props.control}
        defaultValue="3600"
        name="expiration"
        as={
          <RadioGroup row sx={{ justifyContent: 'space-around' }}>
            <FormControlLabel
              labelPlacement="end"
              value="3600"
              control={<Radio color="primary" size="small" />}
              color="textPrimary"
              label={<Typography variant="body2" color="textPrimary">{t('expiration.optionOneHourLabel') as string}</Typography>}
            />
            <FormControlLabel
              labelPlacement="end"
              value="86400"
              control={<Radio size="small" color="primary" />}
              label={<Typography variant="body2" color="textPrimary">{t('expiration.optionOneDayLabel') as string}</Typography>}
            />
            <FormControlLabel
              labelPlacement="end"
              value="604800"
              control={<Radio size="small" color="primary" />}
              label={<Typography variant="body2" color="textPrimary">{t('expiration.optionOneWeekLabel') as string}</Typography>}
            />
          </RadioGroup>
        }
      />
    </FormControl>
  );
};
export default Expiration;
