import { AppBar, Toolbar, Typography, Box, Link } from '@mui/material';
// import { useTranslation } from 'react-i18next';
// import { useLocation } from 'react-router-dom';

export const Header = () => {
  // const { t } = useTranslation();
  // const location = useLocation();
  // const isOnUploadPage = location.pathname.includes('upload');
  const base = process.env.PUBLIC_URL || '';
  const home = base + '/#/';
  // const upload = base + '/#/upload';
  return (
    <AppBar position="static" elevation={0} color="transparent" sx={{ mb: 5 }}>
      <Toolbar sx={{ justifyContent: 'center' }}>
        <Link href={home} color="inherit" underline="none">
          <Typography variant="h6" component="div">
            {/*Securewell*/}
            <Box
              sx={{
                verticalAlign: 'middle',
                paddingLeft: '5px',
                width: 'auto',
                height: '24px',
                opacity: '0.87'
              }}
              component="img"
              alt="Securewell"
              src="securewell.png"
            />
          </Typography>
        </Link>
        {/*<Box*/}
        {/*  sx={{*/}
        {/*    marginLeft: 'auto',*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <Button*/}
        {/*    component={Link}*/}
        {/*    href={isOnUploadPage ? home : upload}*/}
        {/*    variant="contained"*/}
        {/*    color="primary"*/}
        {/*  >*/}
        {/*    {isOnUploadPage ? t('header.buttonHome') : t('header.buttonUpload')}*/}
        {/*  </Button>*/}
        {/*</Box>*/}
      </Toolbar>
    </AppBar>
  );
};
