import { useCopyToClipboard } from 'react-use';
import { useState } from 'react';
import { Alert, Button, Snackbar } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

type CopyBtnProps = {
  readonly value: string;
  readonly fullWidth?: boolean;
  readonly text?: string;

};

const CopyBtn = ({ value, fullWidth = false, text = 'Copy' }: CopyBtnProps) => {
  const [copy, copyToClipboard] = useCopyToClipboard();
  const [copyConfirm, setCopyConfirm] = useState(false);

  function handleClipboardBtn(value: string) {
    copyToClipboard(value);
    setCopyConfirm(true);
  }

  return <>
    <Snackbar open={copyConfirm} autoHideDuration={2000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              onClose={() => setCopyConfirm(false)}>
      <Alert severity="success" sx={{ width: '100%' }}>
        {/*<AlertTitle>Copied To Clipboard</AlertTitle>*/}
        Copied To Clipboard
      </Alert>
    </Snackbar>
    <Button
      fullWidth={fullWidth}
      color={copy.error ? 'secondary' : 'primary'}
      variant="contained"
      onClick={() => handleClipboardBtn(value)}
      startIcon={<FontAwesomeIcon icon={faCopy} />}
    >{text}</Button>
  </>;
}

export default CopyBtn;
