import {
  faBomb,
  faCodeBranch,
  faDownload,
  faLock,
  faShareAlt,
  faUserAltSlash,
  IconDefinition,
  faPlusCircle, faMinusCircle
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Grid, Paper, Typography, Divider, Box, Link, Chip } from '@mui/material';

export const Features = () => {
  const [showFeatures, setShowFeatures] = useState(false)
  const { t } = useTranslation();
  return (
    <>
      <Divider variant="middle" sx={{ mt: 6, '&:before,&:after': { top: 0 }}}>
        <Chip label={<Link onClick={() => setShowFeatures(!showFeatures)} variant="body2" color="textSecondary" sx={{ textDecoration: 'none', '&:hover': { textDecoration: 'underline', cursor: 'pointer' }}}>
          About <FontAwesomeIcon size="xs" icon={showFeatures ? faMinusCircle : faPlusCircle} />
        </Link>} />
      </Divider>

      <Grid container={true} display={showFeatures ? 'flex' : 'none'}>

        <Grid item={true} xs={12}>
          <Box p={2} mt={2}>
            <Typography variant="h5" align={'center'} mb={1}  color="textPrimary">
              Share Secrets Securely With&nbsp;Ease
            </Typography>
            <Typography variant="subtitle1" align={'center'}  color="textPrimary">
              {t('features.subtitle')}
            </Typography>
          </Box>
        </Grid>

        <Feature title={t('features.featureEndToEndTitle')} icon={faLock}>
          {t('features.featureEndToEndText')}
        </Feature>
        <Feature title={t('features.featureSelfDestructionTitle')} icon={faBomb}>
          {t('features.featureSelfDestructionText')}
        </Feature>
        <Feature title={t('features.featureOneTimeTitle')} icon={faDownload}>
          {t('features.featureOneTimeText')}
        </Feature>
        <Feature title={t('features.featureSimpleSharingTitle')} icon={faShareAlt}>
          {t('features.featureSimpleSharingText')}
        </Feature>
        <Feature title={t('features.featureNoAccountsTitle')} icon={faUserAltSlash}>
          {t('features.featureNoAccountsText')}
        </Feature>
        <Feature title={t('features.featureOpenSourceTitle')} icon={faCodeBranch}>
          {t('features.featureOpenSourceText')}
        </Feature>
      </Grid>

      <Typography mt={2} variant="body2" color="textSecondary" align="center" display={showFeatures ? 'block' : 'none'}>
        {'This service is a fork of '}
        <Link href="https://yopass.se" color="textSecondary">Yopass</Link>
        {' by '}
        <Link href="https://github.com/jhaals/yopass" color="textSecondary">Johan Haals</Link>
      </Typography>
    </>
  );
};

type FeatureProps = {
  readonly title: string;
  readonly icon: IconDefinition;
  readonly children: JSX.Element;
};

const Feature = (props: FeatureProps) => {
  return (
    <Grid item={true} xs={12} md={6}>
      <Paper
        elevation={0}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '16px',
          backgroundColor:'transparent',

        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'flex-end' }} mb={2} color="textPrimary">
          <FontAwesomeIcon size={'3x'} icon={props.icon}  />
          <Typography variant="h6" ml={2} lineHeight={1.2}>{props.title}</Typography>
        </Box>
        <Typography variant="body2" sx={{ textAlign: ['left',null,'left'] }}>{props.children}</Typography>
      </Paper>
    </Grid>
  );
};
