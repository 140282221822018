import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Box
  // IconButton
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CopyBtn from '../shared/CopyBtn';

type ResultProps = {
  readonly uuid: string;
  readonly password: string;
  readonly prefix: 's' | 'f';
  readonly customPassword?: boolean;
};

const Result = ({ uuid, password, prefix, customPassword }: ResultProps) => {
  const base =
    (process.env.PUBLIC_URL ||
      `${window.location.protocol}//${window.location.host}`) + `/#/${prefix}`;
  const short = `${base}/${uuid}`;
  const full = `${short}/${password}`;
  const { t } = useTranslation();

  return (
    <Box>
      <Typography component="h1" variant="h4" align="center" color="textPrimary">
        Message <Box component="span" sx={{ fontWeight: '200' }}>Saved</Box>
      </Typography>
      <Typography component="p" variant="body1"  color="textPrimary" mt={1} mb={2}>
        {t('result.subtitleDownloadOnce')}
      </Typography>

      <TableContainer sx={{ overflow: 'hidden'}}>
        <Table sx={{ "& td": { border: 0 } }}>
          <TableBody>
            {!customPassword && (
              <Row label={t('result.rowLabelOneClick')} value={full} />
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Typography component="p" variant="body1"  color="textPrimary" mt={2}>
        {t('result.subtitleChannel')}
      </Typography>

      <TableContainer sx={{ overflow: 'hidden'}}>
        <Table sx={{ "& tr td": { border: 0 } }}>
          <TableBody>
            <Row label={t('result.rowLabelShortLink')} value={short} />
            <Row label={t('result.rowLabelDecryptionKey')} pt={0} value={password} />
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

type RowProps = {
  readonly label: string;
  readonly value: string;
  readonly pt?: any;
};

const Row = ({ label, value, pt = 2}: RowProps) => {

  return (
    <TableRow key={label}>
      <TableCell valign="top" sx={{ verticalAlign: 'top', p: 0, pt }}>
        <CopyBtn value={value} />
        {/*<IconButton color={copy.error ? 'secondary' : 'primary'} onClick={() => copyToClipboard(value)}><FontAwesomeIcon icon={faCopy} /></IconButton>*/}
      </TableCell>
      <TableCell valign="top" sx={{ pt }}>
        <Typography variant="body1" color="textPrimary" fontWeight="bold" noWrap component="div" >{label}</Typography>
        <Typography variant="body2" color="textPrimary" sx={{ fontFamily: 'Monospace' }}>{value}</Typography>
      </TableCell>
    </TableRow>
  );
};

export default Result;
